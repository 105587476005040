import iframeWhisperer from '@grantstreet/iframe-whisperer'
import scrollparent from 'scrollparent'
import type { EmbeddedPublicSiteParams } from '@grantstreet/govhub-vue/src/embedded-demo/EmbeddedDemoUtils.ts'
import { generateEmbeddedPublicSiteIframeUrl } from '@grantstreet/govhub-vue/src/embedded-demo/EmbeddedDemoUtils.ts'
import { trimUrlPart } from '@grantstreet/psc-js/utils/urls.js'
import { ensureRequiredParameters } from '@grantstreet/psc-js/utils/require-parameters.ts'

let childInterface: {
  destroy: () => void
  message: (payload: object) => Promise<unknown>
}

/**
 * Removes the client and site from the provided path. This should return a
 * valid string that the embedding site can pass back into the following attach
 * method. This will allow the embedding site to sync routing, allowing their
 * users to refresh the website, and be taken to the same page on the public
 * site that they were previously on.
 *
 * @returns new router path not containing the client and site
 */
export function removeClientSiteFromPath (site: string, path: string) {
  const ClientAndSite = trimUrlPart(site)
  return trimUrlPart(path).replace(new RegExp(`^${ClientAndSite}`), '')
}

export function attach ({
  site,
  elementSelector,
  rootIframeUrl,
  initialPath,
  language,
  onPathChange,
  googleAnalytics,
  getJwt,
  handleLogin,
  localTaxSysUrl,
}: EmbeddedPublicSiteParams) {
  if (childInterface) {
    childInterface.destroy()
  }

  ; ({
    elementSelector,
    onPathChange,
    getJwt,
    handleLogin,
  } = ensureRequiredParameters({
    elementSelector,
    onPathChange,
    getJwt,
    handleLogin,
  }, 'GsgPublicSite.attach'))

  // Create iframe element
  const iframe = document.createElement('iframe')
  iframe.id = 'gsg-public-site'
  iframe.setAttribute('src', generateEmbeddedPublicSiteIframeUrl({ site, initialPath, rootIframeUrl }))
  iframe.setAttribute('name', 'gsg-public-site')
  iframe.style.cssText = `
    width: 100%;
    height: 100%;
    border: 0px;
    border-radius: 0px;
    box-sizing: border-box;
  `

  if (elementSelector.length <= 1 || elementSelector[0] !== '#') {
    throw new Error('Element selector must be an id tag')
  }

  // Select the containing div that will be the parent to the iframe
  const containingDiv = document.getElementById(elementSelector.substring(1))
  if (!containingDiv) {
    throw new Error(`Element ${elementSelector} not found.`)
  }

  // Ensure the containing div does not constrain the dimensions of the iframe
  containingDiv.style.width = '100%'
  containingDiv.style.height = 'fit-content'

  // 🪄 tada - public site!
  containingDiv.innerHTML = ''
  containingDiv.appendChild(iframe)

  let hasLoaded = false

  // Create the child iframe whisperer
  childInterface = new iframeWhisperer.ToChild({
    iframe,
    messageSource: 'embedded-public-site',
    actions: {
      'routeChange': (newPath: string) => {
        onPathChange(removeClientSiteFromPath(site, newPath))

        // The first routeChange event is whispered after the iframe has loaded.
        // We should skip modifying the scroll position on this initial load,
        // and only change the scroll position as the user interacts with the
        // iframe.
        if (!hasLoaded) {
          hasLoaded = true
          return
        }

        // If the top of the iframe is not visible, scroll up until it is.
        const iframe = document.getElementById('gsg-public-site')
        if ((iframe?.getBoundingClientRect()?.y || 0) < 0) {
          iframe?.scrollIntoView()
        }
      },
      'gsgPublicSite.getJwt': getJwt,
      'gsgPublicSite.handleLogin': handleLogin,
      'gsgPublicSite.scrollToPosition': (childY: number) => {
        const iframe = document.getElementById('gsg-public-site')
        const scrollElement = iframe ? scrollparent(iframe) || window : window
        // -30 is the magic number for leaving a little visual buffer
        scrollElement.scrollTo(0, (iframe?.getBoundingClientRect()?.y || 0) + childY - 30)
      },
    },
  })
  // Send the attach message. This will provide required information to the
  // public site installer such as google analytics information.
  childInterface.message({
    action: 'embeddedPublicSite.attach',
    payload: {
      googleAnalytics,
      localTaxSysUrl,
      language,
      rootTaxSysIframeUrl: rootIframeUrl,
    },
  })
}
